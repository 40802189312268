import React from 'react';
import { NavLink } from 'react-router-dom';
import Resume from "../resume/Fasih'sResume.pdf"
import heroIcon from '../profImage/Icon.svg'

export default function PortfolioNav() {
    return (
        <>
            <div className="home-section container-fluid">
                <nav className="navbar navbar-expand-lg" id='navbar'>
                    <NavLink className="navbar-brand glow" exact to="/" id='logo'>Fasih's Space</NavLink>
                    <button className="navbar-toggler collapsed text-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span id='icon01'> </span>
                        <span id='icon02'> </span>
                        <span id='icon03'> </span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-lg-0 mb-2" id='navbar-nav'>
                            <li className="nav-item">
                                <NavLink className="nav-link menuLink" exact to="/" >Home</NavLink>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link menuLink" href="#about">About</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link menuLink" href="#services">Services</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link menuLink" href="#resume">Resume</a>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link menuLink dropdown-toggle" href="#portfolio" role="button" data-bs-toggle="dropdown" aria-expanded="false" id="navbarDropdown">
                                    My Portfolio
                                </a>
                                <div className="dropdown-menu shadow" aria-labelledby="navbarDropdown" id='dropdwnBg'>
                                    <NavLink className="dropdown-item" to="/textanalyzer" >Text Analyzer</NavLink>
                                    <NavLink exact className="dropdown-item" to="/weather" >Weather Lens</NavLink>
                                    <NavLink exact className="dropdown-item" to="/chat" >SocialChitChat</NavLink>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>

                <section className='row'>
                    <div className='col-lg-4 mainTab'>
                        <h5 className='text-light'>Hello</h5>
                        <h3>I'm <span id='myName'>Fasih</span></h3>
                        <h2 className='changecontent'></h2>
                        <p className='text-light'>To obtain a rewarding position in the field of Web Development & coordinate with a team of experienced professional for my professional growth.</p>
                        <a href={Resume} download={Resume} id='btn1' role="button">HIRE ME</a>
                    </div>
                    <div className='col-lg-4 ml-auto' id='picSection'>
                        <img src={heroIcon} id='heroIcon' />
                    </div>
                </section>
            </div>
        </>
    )
}
{/* <a className="navbar-brand" href="#" id='log'>My Portfolio</a> */ }