import React from 'react';
import PortfolioNav from './PortfolioNav';
import Image01 from '../profImage/pic01.png';
import Resume from "../resume/Fasih'sResume.pdf"
import { NavLink } from 'react-router-dom';

export default function Portfolio() {
    return (
        <>
            <PortfolioNav />
            <div id='about'>
                <div className='container'>
                    <div className='row' id='marginCard'>
                        <div className='col-md-4 py-3 py-md-0'>
                            <div className='card rounded-pill'>
                                <img src={Image01} alt="MyImage" className='rounded-pill' />
                            </div>
                        </div>
                        <div className='col-md-8 py-3 py-md-0 mt-4'>
                            <h3>About Me</h3>
                            <h5>Front-End Developer</h5>
                            <p>Building state of the art easy to use user friendly websites & applications is truly a passion of mine. In addition to my knowledge base, I actively seek out new technologies & stay up-to-date on industry trends & advancements.</p>
                            <h2>Name: <span>Fasih Ur Rehman</span></h2>
                            <h2>From: <span>Pakistan</span></h2>
                            <h2 className='mb-2'>Email: <span>fasihrana7@gmail.com</span></h2>
                            <a href={Resume} download={Resume} id='btncv2' role="button">Download Resume</a>
                        </div>
                    </div>
                </div>
            </div>

            <div id='services'>
                <div className='container'>
                    <h3>Services</h3>
                    <div className='row' id='cardMargin2'>
                        <div className='col-lg-3 py-3 py-md-0'>
                            <div className='card'>
                                <i className='fa-solid fa-code' style={{ color: 'white' }}></i>
                                <h2>Front-End
                                    <br /> Development
                                </h2>
                            </div>
                        </div>
                        <div className='col-lg-3 py-3 py-md-0'>
                            <div className='card'>
                                <i className='fa-solid fa-code' style={{ color: 'white' }}></i>
                                <h2>UI/UX
                                    <br /> Development
                                </h2>
                            </div>
                        </div>
                        <div className='col-lg-3 py-3 py-md-0'>
                            <div className='card'>
                                <i className='fa-solid fa-code' style={{ color: 'white' }}></i>
                                <h2>React
                                    <br /> Development
                                </h2>
                            </div>
                        </div>
                        <div className='col-lg-3 py-3 py-md-0'>
                            <div className='card'>
                                <i className='fa-solid fa-code' style={{ color: 'white' }}></i>
                                <h2>Web
                                    <br /> Development
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id='resume'>
                <div className='container' id='cardMargin2'>
                    <h5 className='text-center'>RESUME</h5>
                    <div className='container'>
                        <div className='hr' style={{ marginTop: '30px' }}></div>
                    </div>
                    <div className='row justify-content-center' id='cardMargin3'>
                        <div className='col-md-6 py-3 py-md-0'>
                            <h3><i className='fa-solid fa-graduation-cap'></i> Education</h3>
                            <span className='d-block text-light mt-4'>University of Agriculture</span>
                            <i className='fa-solid fa-building mt-2 mb-2'> 2016-2020</i>
                            <h2>Bachelor's in Computer Science</h2>

                            <span className='d-block text-light mt-5'>Kips College</span>
                            <i className='fa-solid fa-building mt-2 mb-2'> 2014-2016</i>
                            <h2>ICS</h2>

                            <span className='d-block text-light mt-5'>AIC</span>
                            <i className='fa-solid fa-building mt-2 mb-2'> 2014-2016</i>
                            <h2>Matriculation</h2>
                        </div>

                        <div className='col-md-6 py-3 py-md-0'>
                            <h3><i className='fa-solid fa-briefcase'></i> Experience</h3>
                            <span className='d-block text-light mt-4'>TecSpine</span>
                            <i className='fa-solid fa-building mt-2 mb-2'> 12/2022 - Present</i>
                            <h2>Front-End Developer (Remote)</h2>

                            <span className='d-block text-light mt-5'>Al-Nahar Solutions</span>
                            <i className='fa-solid fa-building mt-2 mb-2'> 05/2021 - 04/2022</i>
                            <h2>Front-End Developer</h2>

                            <span className='d-block text-light mt-5'>PTCL</span>
                            <i className='fa-solid fa-building mt-2 mb-2'> 10/2021 - 12/2021</i>
                            <h2>Switching & Routing</h2>
                        </div>
                        <div className="container mb-5 mt-5">
                            <h3><i className="fa-solid fa-gear"></i> Skills</h3>
                            <label htmlFor="" className='text-light mt-3'>Html</label>
                            <div className="progress shadow" style={{ height: '20px' }}>
                                <div className="progress-bar progress-bar progress-bar-animated bg-info font-weight-bold" style={{ width: '80%' }}>
                                </div>
                            </div>
                            <label htmlFor="" className='text-light mt-3'>Css</label>
                            <div className="progress shadow" style={{ height: '20px' }}>
                                <div className="progress-bar progress-bar progress-bar-animated bg-info font-weight-bold" style={{ width: '80%' }}>
                                </div>
                            </div>
                            <label htmlFor="" className='text-light mt-3'>Bootstrap</label>
                            <div className="progress shadow" style={{ height: '20px' }}>
                                <div className="progress-bar progress-bar progress-bar-animated bg-info font-weight-bold" style={{ width: '70%' }}>
                                </div>
                            </div>
                            <label htmlFor="" className='text-light mt-3'>JavaScript</label>
                            <div className="progress shadow" style={{ height: '20px' }}>
                                <div className="progress-bar progress-bar progress-bar-animated bg-info font-weight-bold" style={{ width: '60%' }}>
                                </div>
                            </div>
                            <label htmlFor="" className='text-light mt-3'>React JS</label>
                            <div className="progress shadow" style={{ height: '20px' }}>
                                <div className="progress-bar progress-bar progress-bar-animated bg-info font-weight-bold" style={{ width: '60%' }}>
                                </div>
                            </div>
                            <label htmlFor="" className='text-light mt-3'>jQuery</label>
                            <div className="progress shadow" style={{ height: '20px' }}>
                                <div className="progress-bar progress-bar progress-bar-animated bg-info font-weight-bold" style={{ width: '70%' }}>
                                </div>
                            </div>
                        </div>
                        <div className='hr' style={{ marginTop: '30px' }}></div>
                    </div>
                </div>
            </div>
            <div id='portfolio'>
                <div className='container p-3'>
                    <h1 className='text-center'>My Work</h1>

                    <div className='row justify-content-center' id='cardMargin04' style={{ marginTop: '30px' }}>
                        <div className='col-lg-3 paddingFix000'>
                            <NavLink to="/textanalyzer" ><i className="fa-solid fa-text-width"></i><p className='appsName'>Text Analyzer</p></NavLink>
                        </div>
                        <div className='col-lg-3 paddingFix000'>
                            <NavLink to="/weather" ><i className="fa-solid fa-cloud"></i><p className='appsName'>Weather Lens</p></NavLink>
                        </div>
                        <div className='col-lg-3 paddingFix000'>
                            <NavLink to="/chat" ><i className="fa-solid fa-comment"></i><p className='appsName'>SocialChitChat</p></NavLink>
                        </div>
                    </div>
                </div>
            </div>

            <div id='contact'>
                <div className='container p-3'>
                    <div className='hr'></div>
                    <div className='row justify-content-center' id='cardMargin05'>
                        <div className='col-md-4 py-3 py-md-0 m-auto text-center'>
                            <h2>Let's Connect</h2>
                            <a href="https://www.linkedin.com/in/fasihrana/" target='_blank'><i className='fa-brands fa-linkedin fa-fade'></i></a>
                            <a href="mailto:fasihrana7@gmail.com" target='_blank'><i className="fa-solid fa-envelope fa-fade"></i></a>
                            <a href="https://twitter.com/RanaFasih98" target='_blank'><i className='fa-brands fa-twitter fa-fade'></i></a>
                            <a href="https://www.instagram.com/fasih.98/" target='_blank'><i className='fa-brands fa-instagram fa-fade'></i></a>
                        </div>

                        <div className='col-md-4 py-3 py-md-0 text-center m-auto'>
                            <h2>Site Links</h2>
                            <NavLink className='d-block mt-3 font-weight-bold text-light' to='/'>Home</NavLink>
                            <NavLink className='d-block mt-3 font-weight-bold text-light' to='/textanalyzer'>Text Analyzer</NavLink>
                            <NavLink className='d-block mt-3 font-weight-bold text-light' to='/weather'>Weather Lens</NavLink>
                            <NavLink className='d-block mt-3 font-weight-bold text-light' to='/chat'>SocialChitChat</NavLink>
                        </div>
                    </div>
                </div>
            </div>

            <footer className='container-fluid' id='footer'>
                <div className='text-center'>
                    Designed by Rana Fasih.
                </div>
            </footer>
        </>
    )
}
