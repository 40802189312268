import React from 'react';
import { ChatEngine } from 'react-chat-engine';
import ChatNav from "./ChatNav";
// import ChatFeed from "./ChatFeed";
import LoginForm from './LoginForm';

export default function ChatApp() {

  if (!localStorage.getItem('username')) return <LoginForm />

  return (
    <>
      <ChatNav />
      <ChatEngine
        height="100vh"
        projectID="d15c8fa0-6ab7-49c0-85f2-1cdfb1e870c6"
        userName={localStorage.getItem('username')}
        userSecret={localStorage.getItem('password')}
      // renderChatFeed={(chatAppProps) => <ChatFeed {...chatAppProps}/>}
      />
      <footer className='container-fluid' id='footer3'>
        <div className='text-center'>
          <a href="https://www.linkedin.com/in/fasihrana/" target='_blank'><i className='fa-brands text-dark mx-2 fa-linkedin fa-fade'></i></a>
          <a href="mailto:fasihrana7@gmail.com" target='_blank'><i className="fa-solid mx-2 text-dark fa-envelope fa-fade"></i></a>
          <a href="https://twitter.com/RanaFasih98" target='_blank'><i className='fa-brands text-dark  mx-2 fa-twitter fa-fade'></i></a>
          <a href="https://www.instagram.com/fasih.98/" target='_blank'><i className='fa-brands text-dark mx-2 fa-instagram fa-fade'></i></a>
        </div>
      </footer>
    </>
  )
}


// import NewApp from './NewApp';
// const [text, setText] = useState('');

// const searchBox = (e) => {
//   const data = e.target.value;
//   setText(data);
// }
/* <input type="text" className="ml-auto mr-auto mt-2 d-block" onChange={searchBox} value={text}/>
{text === '' ? null: <NewApp name={text}/>}   */

// import { useLocation, useParams, useNavigate } from 'react-router-dom';
// const { fname4, lname5 } = useParams();
// const location = useLocation();
// const history = useNavigate();
/* <h2> this is {fname4} {lname5}</h2>
<p>{location.pathname}</p>
{location.pathname === `/app2/fasih/rehman` ? (
  <button onClick={() => alert("Hey")}>Founded</button> ) :null }
  <button onClick={() => history(-1)}>Go back</button>
  <button onClick={() => history('/')}>Homepage</button> */