import { useState } from 'react';
import axios from 'axios';
import ChatNav from './ChatNav';

const projectID = 'd15c8fa0-6ab7-49c0-85f2-1cdfb1e870c6';

const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const authObject = { 'Project-ID': projectID, 'User-Name': username, 'User-Secret': password };

    try {
      await axios.get(`https://api.chatengine.io/chats`, { headers: authObject });

      localStorage.setItem('username', username);
      localStorage.setItem('password', password);

      window.location.reload();
      setError('');
    } catch (err) {
      setError('Invalid username/password.');
    }
  };

  return (
    <>
      <ChatNav />
      <div className='container-fluid p-5 text-center' id='wrap-2'>
        <h2>Note:</h2>
        <p className='mt-3'>Use any one of the following credentials to login:</p>
        <p>1. Username: user1, Password: 123123</p>
        <p>2. Username: user2, Password: 123123</p>
        <p>3. Username: user3, Password: 123123</p>
        <p>4. Username: user4, Password: 123123</p>
        <h4>If there's an error 404 in redirecting kindly go to 'Homepage' & navigate to 'SocialChitChat' and you will be redirected to the chat section.</h4>
      </div>
      <div className="wrapper">
        <div className="form">
          <h1 className="title">Login</h1>
          <form onSubmit={handleSubmit}>
            <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} className="input" placeholder="Username" required />
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="input" placeholder="Password" required />
            <h6 className='text-center text-danger mb-3'>{error}</h6>
            <div align="center">
              <button type="submit" className="button">
                <span>Let's Go</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>

  );
};

export default LoginForm;
