import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function Error() {
    const history = useNavigate()
    return (
        <div className='container-fluid mr-auto ml-auto text-center bg-dark p-5' style={{ height: '100vh' }}>
            <h1 style={{ fontSize: '4rem', marginTop: '17.5rem', color: 'white', fontWeight: 'bolder' }}>404 PAGE NOT FOUND</h1>
            <button style={{ fontSize: '1.2rem', marginTop: '1.5rem', fontWeight: '500' }} className='p-2 btn btn-outline-light' onClick={() => history('/')}>Go to Homepage</button>
        </div>
    )
}
