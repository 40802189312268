import React from 'react';
import { NavLink } from 'react-router-dom';

export default function ChatNav() {
    return (
        <>
            <nav className="navbar navbar-expand-lg container-fluid" style={{ backgroundColor: 'rgb(20 255 75 / 53%)' }}>

                <NavLink className="navbar-brand mr-auto text-dark" id='appNav' to="/chat">SocialChitChat</NavLink>
                <button className="navbar-toggler collapsed text-dark" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span> </span>
                    <span> </span>
                    <span> </span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav  mx-auto text-center">
                        <li className="nav-item">
                            <NavLink className="nav-link menuItem text-dark" exact to="/" >Home</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link text-dark menuItem" exact to="/textanalyzer" >Text Analyzer</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink exact className="nav-link menuItem text-dark " to="/weather" >Weather Lens</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink exact className="nav-link menuItem text-dark" to="/chat" >SocialChitChat</NavLink>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    )
}
