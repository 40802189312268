import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

export default function WeatherApp() {

  const [city, setCity] = useState("Faisalabad");
  const [api, setApi] = useState();
  const [api1, setApi1] = useState();
  const [api3, setApi3] = useState();
  const [api4, setApi4] = useState();

  useEffect(() => {
    const fetchApi = async () => {
      const data = `https://api.openweathermap.org/data/2.5/weather?q=${city}&units=metric&appid=ffa7627690b961808eae78dc482c29d5`;
      const response = await fetch(data);
      const resJSON = await response.json();
      setApi(resJSON.main);
    }
    fetchApi();
  }, [city]);

  useEffect(() => {
    const fetchApi2 = async () => {
      const data1 = `https://api.openweathermap.org/data/2.5/weather?q=${city}&units=metric&appid=ffa7627690b961808eae78dc482c29d5`;
      const response1 = await fetch(data1);
      const resJSON1 = await response1.json();
      setApi1(resJSON1.wind);
    }
    fetchApi2();
  }, [city]);

  useEffect(() => {
    const fetchApi4 = async () => {
      const data3 = `https://api.openweathermap.org/data/2.5/weather?q=${city}&units=metric&appid=ffa7627690b961808eae78dc482c29d5`;
      const response3 = await fetch(data3);
      const resJSON3 = await response3.json();
      setApi3(resJSON3.sys);
    }
    fetchApi4();
  }, [city]);

  useEffect(() => {
    const fetchApi5 = async () => {
      const data4 = `https://api.openweathermap.org/data/2.5/weather?q=${city}&units=metric&appid=ffa7627690b961808eae78dc482c29d5`;
      const response4 = await fetch(data4);
      const resJSON4 = await response4.json();
      setApi4(resJSON4.clouds);
    }
    fetchApi5();
  }, [city]);


  return (
    <>
      <div className='bgMain'>
        <nav className="navbar navbar-expand-lg container-fluid">
          <NavLink className="navbar-brand mr-auto" to="/weather">Weather Lens</NavLink>
          <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className='textWhite'></span>
            <span className='textWhite'></span>
            <span className='textWhite'></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav  mx-auto text-center">
              <li className="nav-item">
                <NavLink className="nav-link text-light menuItem" exact to="/" >Home</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link text-light menuItem" exact to="/textanalyzer" >Text Analyzer</NavLink>
              </li>
              <li className="nav-item">
                <NavLink exact className="nav-link text-light menuItem " to="/weather" >Weather Lens</NavLink>
              </li>
              <li className="nav-item">
                <NavLink exact className="nav-link text-light menuItem" to="/chat" >SocialChitChat</NavLink>
              </li>
            </ul>
          </div>
        </nav>

        <div className=' weatherMain'>
          <input className=" mx-auto d-block text-center weatherSrch mt-4 p-2" type="search" placeholder="Enter your city" aria-label="Search" onChange={(i) => {
            const cityName = i.target.value;
            setCity(cityName)
          }} value={city} id='search' />
          <div className='row justify-content-center'>
            <div className='col-md-5 weatherChild01'>

              {!api || !api1 || !api3 || !api4 ? (
                <p className='text-danger'>No Data Found</p>
              ) : (<>
                <h1 className='mt-3'>{api3.country}</h1>
                <h1 className=''>{city}</h1>
                <h2> {api.temp}°C</h2>
                <h4>Real Feel {api.feels_like}°C</h4>
                <span className=''>Max: {api.temp_max}°C Min: {api.temp_min}°C</span>
                <br />
                <span className='mr-1'>Humidity: {api.humidity}%</span> <span className='ml-1'>Pressure: {api.pressure}mbar</span>
                <br />
                <span>Wind Speed: {api1.speed} KM</span>
                <br />
                <span>Cloud Cover: {api4.all}%</span>
              </>
              )}
            </div>
          </div>
        </div>
      </div>
      <footer className='container-fluid' id='footer1'>
        <div className='text-center'>
          <a href="https://www.linkedin.com/in/fasihrana/" target='_blank'><i className='fa-brands text-light mx-2 fa-linkedin fa-fade'></i></a>
          <a href="mailto:fasihrana7@gmail.com" target='_blank'><i className="fa-solid mx-2 text-light fa-envelope fa-fade"></i></a>
          <a href="https://twitter.com/RanaFasih98" target='_blank'><i className='fa-brands text-light mx-2 fa-twitter fa-fade'></i></a>
          <a href="https://www.instagram.com/fasih.98/" target='_blank'><i className='fa-brands text-light mx-2 fa-instagram fa-fade'></i></a>
        </div>
      </footer>
    </>
  )
}
