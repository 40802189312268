
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

export default function TextBox(props) {

    const upCase = () => {
        let textNew = text.toUpperCase();
        setText(textNew);
    }

    const lowCase = () => {
        let textNew = text.toLowerCase();
        setText(textNew);
    }

    const clearText = () => {
        let textNew = "";
        setText(textNew);
    }

    const extraSpace = () => {
        let textNew = text.split(' ');
        let joinedWords = '';
        textNew.forEach((elem) => {
            if (elem[0] !== undefined) {
                joinedWords += elem + " ";
                console.log(joinedWords);
            }
        })
        setText(joinedWords);
    }

    const capsWord = () => {
        let newText = text.split(" ").map(el => el.charAt(0).toUpperCase() + el.slice(1)).join(" ");
        setText(newText);
    }

    const handleChange = (event) => {
        setText(event.target.value);
    }

    const fontFami = () => {
        alert("Make sure that the font is installed in your device.")
        let input = prompt("Enter the font name:")
        setFont(input)
    }

    const changeCol = () => {
        let mycolor = prompt("Enter the color name: (Without space)");
        setColor(mycolor);

    }

    const readTxt = (event) => {
        let file = event.target.files[0];
        let reader = new FileReader();
        reader.onload = function (event) {
            setText(event.target.result);
        };
        reader.readAsText(file);
    }

    const copyClip = () => {
        navigator.clipboard.writeText(text)
            .then(() => {
                console.log(text + " copied ");
            })
            .catch((error) => {
                console.error('Error copying text: ', error);
            });
    }

    const [text, setText] = useState("")
    const [font, setFont] = useState("")
    const [colorCha, setColor] = useState("")

    const [btnColor, changeBtn] = useState({
        border: "1px solid #343a40"
    })

    const [bgColor, changeMyBg] = useState({
        color: "black",
        backgroundColor: "white"
    })

    const [toggleTxt, myModTxt] = useState("Toggle Dark Mode");

    const toggleMod = () => {
        if (bgColor.color === "black") {
            changeMyBg({
                color: "white",
                backgroundColor: "#343a40",
                transition: "linear 0.2s"
            })
            changeBtn({
                border: "1px solid white",
                color: "white",
                transition: "linear 0.2s"
            })
            myModTxt("Toggle Light Mode")
        } else {
            changeMyBg({
                color: "black",
                backgroundColor: "white",
                transition: "linear 0.2s"
            })
            changeBtn({
                border: "1px solid #343a40",
                transition: "linear 0.2s"
            })
            myModTxt("Toggle Dark Mode")
        }
    }

    return (

        <>

            <div style={bgColor} className='paddingFix'>

                <nav className="navbar fixed-top navbar-expand-lg bg-dark shadow container-fluid">

                    <NavLink className="navbar-brand mr-auto" to="/">{props.title}</NavLink>
                    <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className='textWhite'></span>
                        <span className='textWhite'></span>
                        <span className='textWhite'></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav  m-auto text-center">
                            <li className="nav-item">
                                <NavLink className="nav-link text-light menuItem" exact to="/" >Home</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link text-light menuItem" exact to="/textanalyzer" >Text Analyzer</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink exact className="nav-link text-light menuItem " to="/weather" >Weather Lens</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink exact className="nav-link text-light menuItem" to="/chat" >SocialChitChat</NavLink>
                            </li>
                        </ul>

                        <button className="btn btn-outline-light" onClick={toggleMod} id='btnMargin02'>{toggleTxt}</button>
                    </div>
                </nav>

                <div className='container mt-5 paddingFix' style={bgColor}>

                    <h2 className='m-auto text-center' id='heading01'>{props.heading}</h2>

                    <div className="mb-3 mt-5 container custom-file mx-auto d-block" id='container02'>
                        <input type="file" className=" custom-file-input" id="validatedCustomFile" accept="text/plain" onChange={readTxt} />
                        <label className="custom-file-label" for="validatedCustomFile">Choose file...</label>
                    </div>

                    <button className='btn btn-dark mt-4 ml-auto d-block' onClick={copyClip} style={btnColor}>Copy</button>
                    <div className="form-group mt-3">
                        <textarea className="form-control p-3" id="exampleFormControlTextarea1" style={{ fontFamily: font, color: colorCha }} rows="13" value={text} onChange={handleChange}></textarea>
                    </div>

                    <div className='row justify-content-center'>
                        <div className='col-md-10 py-3 py-md-0 m-auto text-center' id='btnMargin'>
                            <button className="btn btn-outline-secondary mt-3 p-2" style={btnColor} onClick={upCase}>Convert to Uppercase</button>
                            <button className="btn btn-outline-secondary mt-3 p-2 mx-2" style={btnColor} onClick={lowCase}>Convert to Lowercase</button>
                            <button className="btn btn-outline-secondary mt-3 p-2" style={btnColor} onClick={capsWord}>Capitalize First Letter </button>
                            <button className="btn btn-outline-secondary mt-3 p-2" id='rmvXtr' style={btnColor} onClick={extraSpace}>Remove Extra Spaces</button>
                            <button className="btn btn-outline-secondary mt-3 p-2" style={btnColor} onClick={changeCol}>Change Text Color </button>
                            <button className="btn btn-outline-secondary mt-3 p-2 mx-2" style={btnColor} onClick={fontFami}>Change Font </button>
                            <button className="btn btn-outline-secondary mt-3 p-2 mx-2" style={btnColor} onClick={clearText}>Clear Text</button>
                        </div>
                    </div>
                </div>

                <div className="container mt-4" style={bgColor}>
                    <h3>Counter:</h3>
                    <p><b>{text.split(" ").filter((i) => { return i.length !== 0 }).length}</b> Words & <b>{text.length}</b> Characters</p>
                    <p><b>{Math.round(.008 * text.split(" ").length)}</b> Reading Minutes</p>
                </div>
                <div className="container">
                    <h3 className='mr-auto ml-auto mt-4' style={bgColor}> Preview:</h3>
                </div>
                <div className="container mt-2 p-3 rounded text-break" style={{ fontFamily: font, color: colorCha, border: "1px solid gray" }}>
                    <p className='mb-0'>{text}</p>
                </div>

            </div>

            <footer className='container-fluid bg-dark' id='footer2'>
                <div className='text-center'>
                    <a href="https://www.linkedin.com/in/fasihrana/" target='_blank'><i className='fa-brands text-light mx-2 fa-linkedin fa-fade'></i></a>
                    <a href="mailto:fasihrana7@gmail.com" target='_blank'><i className="fa-solid mx-2 text-light fa-envelope fa-fade"></i></a>
                    <a href="https://twitter.com/RanaFasih98" target='_blank'><i className='fa-brands text-light mx-2 fa-twitter fa-fade'></i></a>
                    <a href="https://www.instagram.com/fasih.98/" target='_blank'><i className='fa-brands text-light mx-2 fa-instagram fa-fade'></i></a>
                </div>
            </footer>
        </>
    )
}
