import { Routes, Route } from 'react-router-dom';
import './App.css';
import TextBox from "./components/Text";
import Weather from './components/WeatherApp';
import ChatApp from './components/ChatApp';
import Error from './components/Error';
import Portfolio from './components/Portfolio';




function App() {
  return (
    <>
      <Routes>
        <Route exact path='/' element={<Portfolio />} />
        <Route exact path='/textanalyzer' element={<TextBox heading="Enter the text below or Upload a .text file only to analyze" title="Text Analyzer" />} />
        <Route exact path='/weather' element={<Weather />} />
        <Route exact path='/chat' element={<ChatApp />} />
        <Route exact path='*' element={<Error />} />
      </Routes>

    </>
  )
}

export default App;

//  <Route path="*" element={<Navigate to ="/" />}/>  For REDIRECTION